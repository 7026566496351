import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import PasswordField from 'components/fields/PasswordField';
import { Button, Form, FormGroup } from 'reactstrap';

const ConfirmPasswordForm = ({
  isPasswordVisible,
  onPasswordToggle,
  isRequestPending,
  handleSubmit,
  onSubmit,
  displayCurrentPassword,
  PasswordComplexityFeature
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
     {displayCurrentPassword &&(
     <PasswordField
     current
     label="Current Password"
     displayPassword={isPasswordVisible}
     onToggle={onPasswordToggle}
     disabled={isRequestPending}
     featureToggle={!PasswordComplexityFeature}
   />
    )}
    <PasswordField
      label="New Password"
      displayPassword={isPasswordVisible}
      onToggle={onPasswordToggle}
      disabled={isRequestPending}
      featureToggle={!PasswordComplexityFeature}
    />
    <PasswordField
      confirm
      label="Confirm New Password"
      displayPassword={isPasswordVisible}
      onToggle={onPasswordToggle}
      disabled={isRequestPending}
      featureToggle={!PasswordComplexityFeature}
    />
    <FormGroup>
      <Button block disabled={isRequestPending} color="primary">
        Reset Password
      </Button>
    </FormGroup>
  </Form>
);

ConfirmPasswordForm.propTypes = {
  isPasswordVisible: PropTypes.bool,
  onPasswordToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
  PasswordComplexityFeature: PropTypes.bool,
  displayCurrentPassword: PropTypes.bool,
};

ConfirmPasswordForm.defaultProps = {
  isPasswordVisible: false,
  isRequestPending: false,
  displayCurrentPassword: false,
};

const ConnectedConfirmPasswordForm = reduxForm({
  form: 'confirm-password',
})(ConfirmPasswordForm);

export default ConnectedConfirmPasswordForm;
export { ConfirmPasswordForm as PureConfirmPasswordForm };
