import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import PasswordInput from '../../core/PasswordInput';
import {
  required,
  minLength,
  matchesPassword,
  noRepeatingCharacters,
  noSequentialCharacters,
  notCommonPassword,
  noEndSpaces,
  containsCapitalLetter,
  containsLowerCaseLetter,
  containsNumber,
  containsSpecialCharacter
} from './validate';

const PasswordFieldComponent = ({
  input,
  meta,
  displayPassword,
  onToggle,
  disabled,
  label,
}) => (
  <PasswordInput
    id={input.name}
    displayPassword={displayPassword}
    onToggle={onToggle}
    disabled={disabled}
    errorMessage={(meta.touched && meta.error) || ''}
    label={label}
    data-private="redact"
    {...input}
  />
);

export const NAME_CONFIRM = 'confirmPassword';
export const NAME_PASSWORD = 'password';
export const NAME_CURRENT = 'currentPassword';

const PasswordField = ({
  disabled,
  displayPassword,
  onToggle,
  confirm,
  current,
  name,
  label,
  skipValidation,
  featureToggle
}) => {
  let validate = skipValidation
    ? [
        required, // The PW Field should always be required even if not validated
      ]
      :current?[required,]: featureToggle? [required, minLength, noRepeatingCharacters, noSequentialCharacters, notCommonPassword, noEndSpaces] : 
      [required, minLength, containsLowerCaseLetter, containsCapitalLetter, containsNumber, containsSpecialCharacter, noRepeatingCharacters, noSequentialCharacters, notCommonPassword, noEndSpaces];
  if (confirm) {
    validate = [...validate, matchesPassword];
  }
  let fieldName = name;
    if (!fieldName) {
        fieldName =current ? NAME_CURRENT : confirm ? NAME_CONFIRM : NAME_PASSWORD;
  }

  let fieldLabel = label;
  if (!fieldLabel) {
       fieldLabel = current ? 'Current Password' :confirm? 'Confirm Password ': 'Password';
  }

  return (
    <Field
      confirm={confirm}
      current={current}
      name={fieldName}
      component={PasswordFieldComponent}
      validate={validate}
      disabled={disabled}
      displayPassword={displayPassword}
      onToggle={onToggle}
      label={fieldLabel}
    />
  );
};

PasswordField.propTypes = {
  disabled: PropTypes.bool,
  displayPassword: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  confirm: PropTypes.bool,
  current: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  skipValidation: PropTypes.bool,
  featureToggle: PropTypes.bool
};

PasswordField.defaultProps = {
  disabled: false,
  displayPassword: false,
  confirm: false,
  current: false,
  name: undefined,
  label: undefined,
  skipValidation: false,
  featureToggle: false
};

export default PasswordField;
export { PasswordFieldComponent };
