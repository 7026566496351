import React, { useState, useContext } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AuthWrapper from 'components/AuthWrapper';
import userShape from 'shapes/userShape';
import * as selectors from 'redux-modules/auth/selectors';
import locationShape from 'shapes/locationShape';
import getInitialValuesFromQuery from 'helpers/getInitialValuesFromQuery';
import redirect from 'helpers/redirect';
import { normalizePhone } from 'components/fields/PhoneNumberField';
import SignInForm from './SignInForm';
import ssoIntegrationOptionsShape from 'shapes/ssoIntegrationOptionsShape';
import PhonePrompt from '../PhonePrompt';
import HpaStoreContext from 'contexts/HpaStoreContext';
import FormatErrorMessage from '../FormatMessage/FormatErrorMessage';
import AuthWrapperFooter from 'components/AuthWrapperFooter';
import identityClient from 'clients/identityClient';
import LoginWithPassword from '../LoginWithPassword';
import { initializePendo } from '../../helpers/pendo';

const SignIn = ({
  location,
  handleRememberMeChange,
  ssoIntegrationOptions,
  username,
}) => {
  const [primaryUserName, setPrimaryUserName] = useState('');
  const [authError, setAuthError] = useState(null);
  const [displayPasswordPage, setDisplayPasswordPage] = useState(false);
  const [errorStatus, updateErrorStatus] = useState(undefined);
  const [recoverError, setRecoverError] = useState(undefined);
  const [recoverEmailResponse, setRecoverEmailResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectors.getUser);
  const oauthError = useSelector(selectors.getOAuthError);
  const promptForPhone = useSelector(selectors.getPromptForPhone);


  const context = useContext(HpaStoreContext);
  const locationHook = useLocation();
  const params = locationHook.search;

  const handleVerifyLoginUsername = async (username) => {
    try {
      const queryString = params ? `${params}` : '';
      await identityClient.validateUsername(username, queryString).then(async (response) => {
        const getAccountData = await identityClient.getAccountInfo(response.hstreamId);
        initializePendo(getAccountData);
      });
    } catch (err) {
      updateErrorStatus(err.response && err.response.status);
      if (err.response && err.response.status === 400) {
        try {
          const response = await identityClient.recoverPrimaryEmail(username);
          setRecoverEmailResponse(response);
        } catch (recoverErr) {
          if (recoverErr.response && recoverErr.response.status >= 400 && recoverErr.response.status <= 499) {
            setRecoverError("The username you have entered is invalid.");
            setRecoverEmailResponse('');
          } else {
            console.error('Failed to recover primary email:', recoverErr);
          }
        }
      }
      throw err;
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    handleRememberMeChange(values.remember_me);
    values.hstreamid = values.hstreamid.trim();
    if (values.hstreamid.indexOf('@') === -1) {
      values.hstreamid = normalizePhone(values.hstreamid);
      let encoded_phone = encodeURIComponent(values.hstreamid);
      setPrimaryUserName(encoded_phone);
      setAuthError(null);
    } else {
      setPrimaryUserName(values.hstreamid);
      setAuthError(null);
    }
    try {
      await handleVerifyLoginUsername(values.hstreamid);
      setDisplayPasswordPage(true);
    } catch (error) {
      setDisplayPasswordPage(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialValues = () => {
    const initialValuesFromQuery = getInitialValuesFromQuery(params, {
      email: '',
      hstreamid: username,
      password: '',
    });

    initialValuesFromQuery.remember_me = ssoIntegrationOptions.rememberMe.value;
    return initialValuesFromQuery;
  };

  if (promptForPhone) {
    return <PhonePrompt ssoIntegrationOptions={ssoIntegrationOptions} />;
  }

  if (user && user.isRegistered) {
    return <Redirect push to="/" />;
  }

  const toRegister = {
    pathname: '/account/register',
    search: location.search,
  };

  const redirectName = redirect.parseRedirectName(location);

  if (oauthError && oauthError.status !== 401) {
    return (
      <Redirect
        to={{
          pathname: '/account/authorize',
          state: { oauthSignInError: true },
        }}
      />
    );
  }
  const shouldSuppressRegister = context && context.customBranding && context.customBranding.suppressRegister && context.customBranding.suppressRegister.toLowerCase() === "true";
  return (
    <div>
      {!displayPasswordPage &&
        <AuthWrapper
          title="Please enter your hStream ID to sign in"
          redirectName={context.customBranding ? `${context.customBranding.displayName}` : redirectName}
          footer={<AuthWrapperFooter />}
        >
          {(recoverEmailResponse || recoverError) && (
            <FormatErrorMessage error="The username you have entered is invalid." recoverEmail={recoverEmailResponse} recoverError={recoverError} />
          )}

          <SignInForm
            initialValues={getInitialValues()}
            onSubmit={onSubmit}
            location={location}
            rememberMeValues={ssoIntegrationOptions.rememberMe}
            isLoading={isLoading}
          />
          {(shouldSuppressRegister === undefined || shouldSuppressRegister === null || shouldSuppressRegister === false) && (
            <>
              <hr />
              <section className="d-flex align-items-center">
                <span style={{ marginRight: '.5em' }}>Need to sign up?</span>
                <Link to={toRegister}>Register Now</Link>
              </section>
            </>
          )}
        </AuthWrapper>
      }
      {displayPasswordPage &&
        <LoginWithPassword
          primaryUserName={primaryUserName}
          ssoIntegrationOptions={ssoIntegrationOptions}
          setDisplayPasswordPage={setDisplayPasswordPage}
        />
      }
    </div>
  );
};

SignIn.propTypes = {
  location: locationShape.isRequired,
  handleRememberMeChange: PropTypes.func.isRequired,
  ssoIntegrationOptions: ssoIntegrationOptionsShape.isRequired,
  username: PropTypes.string.isRequired,
  user: userShape,
  errorMessage: PropTypes.string,
  oauthError: PropTypes.shape({
    status: PropTypes.number,
  }),
  signInError: PropTypes.shape({
    status: PropTypes.number,
  }),
  promptForPhone: PropTypes.bool,
  recoverEmail: PropTypes.string,
  recoverErrorCode: PropTypes.bool,
};

SignIn.defaultProps = {
  user: undefined,
  errorMessage: undefined,
  oauthError: undefined,
  signInError: undefined,
  promptForPhone: undefined,
  recoverEmail: undefined,
  recoverErrorCode: undefined,
};

export default SignIn;
